import { getOpenId, setOpenId, setYzjOpenId } from '@/utils/auth'
import { getOpenId as gop, getOpenId_Json } from '@api/wx.js'

// 获取query(计算一次就失效)
function getQueryVariable(variable) {
  let query = window.location.search.substring(1)
  let vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}

function getopenid() {
  return new Promise((resolve, reject) => {
    const data = {
      code: getQueryVariable('code')
    }
    // gop(data)
    getOpenId_Json(data)
      .then(res => {
        if (res.openid) {
          setOpenId(res.openid)
          if (res.hasemp == '1') {
            localStorage.setItem('v8_login_pwd', res.pwd)
            localStorage.setItem('v8_login_checked', 1)
            localStorage.setItem('v8_login_login', '1')
            localStorage.setItem('v8_login_username', res.userid)
          } else {
            localStorage.setItem('v8_login_login', '0')
          }
          resolve(res.openid)
        } else {
          setOpenId('0')
          reject(false)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function init(callback) {
  // 获取openId
  const hasOpenId = getOpenId()
  if (getQueryVariable('isYZJ') === '1') {
    localStorage.setItem('isYZJ', '1')
  }
  if (hasOpenId) {
    // 有openId
    callback()
  } else {
    // 没有openId
    if (window.APP_ID && !localStorage.isYZJ) {
      // 有设置微信id
      const url = window.location.href.replace(window.location.search, '')
      if (getQueryVariable('code')) {
        // 有code参数
        getopenid()
          .then(openId => {
            if (openId) {
              // console.log(url, 'urlurlurlurlurlurlurl')
              callback()
            } else {
              const redirect = url.replace(
                /(\?code=)[\da-zA-Z]+&state=STATE/gi,
                ''
              )
              console.log(redirect, 'urlurlurlurlurlurlurl')
              window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.APP_ID}&redirect_uri=${redirect}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
            }
          })
          .catch(err => {
            console.log('获取openId失败')
          })
      } else {
        // 没有code参数
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.APP_ID}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
      }
    } else if (localStorage.isYZJ) {
      importJs('https://static.yunzhijia.com/public/js/qing/latest/qing.js')
      callback()
    } else {
      callback()
    }
  }
}

export function importJs(src) {
  // 创建script标签，引入外部文件
  let script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = src
  document.getElementsByTagName('head')[0].appendChild(script)
  // 引入成功
  script.onload = () => {
    if (src === 'https://static.yunzhijia.com/public/js/qing/latest/qing.js') {
      console.log('云之家js桥已加载成功了')
      // 获云之家取个人信息
      qing.call('getPersonInfo', {
        success: function(res) {
          // alert(JSON.stringify(res))
          setYzjOpenId(res.data.openId)
        }
      })
    }
  }
  // 引入失败
  script.onerror = () => {
    console.log('加载失败了')
  }
}
