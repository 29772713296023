import request from '@/utils/request2'

// 获取token
export function getWxApiToken(data) {
  return request({
    url: '/api/login/getWxApiToken',
    method: 'get',
    params: data
  })
}

// 根据用户usertoken获取用户详细信息
export function getUserInfo(params) {
  const { usertoken } = params
  return request({
    url: '/api/wx/getUserInfo',
    method: 'get',
    params: { usertoken }
  })
}

// 修改密码接口
export function changeUserPwd(data) {
  return request({
    url: '/api/wx/changeUserPwd',
    method: 'get',
    params: data
  })
}

// 获取下拉列表的值
export function GetFilterDropDownList(data) {
  return request({
    url: '/api/wx/getFilterDropDownList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 通用 >>> 根据sql语句转换成实际的值 （ps 需要判断前7位字符是`select`）
export function getSqlValue(data) {
  return request({
    url: '/api/baseform/getSqlValue',
    method: 'get',
    params: data
  })
}

//4.	微信端获取用户openid接口
export function getOpenId(data) {
  return request({
    url: '/api/wx/getOpenId',
    method: 'get',
    params: data
  })
}

//微信端获取用户openid接口,带单点登陆信息
export function getOpenId_Json(data) {
  return request({
    url: '/api/wx/getOpenId_Json',
    method: 'get',
    params: data
  })
}

//	根据手机号码绑定openid到人才库
export function bindWxOpenIdByMobilePhone(data) {
  return request({
    url: '/api/wx/bindWxOpenIdByMobilePhone',
    method: 'get',
    params: data
  })
}
//2.	获取首页的自助查询和自助申请列表接口
export function getQueryAndReqList(data) {
  return request({
    url: '/api/wx/getQueryAndReqList',
    method: 'get',
    params: data
  })
}
// 获取语言列表接口
export function getLangList() {
  return request({
    url: '/api/login/getLangList',
    method: 'post'
  })
}
//3.获取首页顶部4个按钮的标题序号和名称信息接口
export function getWxTopBtnList(data) {
  return request({
    url: '/api/wx/getWxTopBtnList',
    method: 'get',
    params: data
  })
}

// 1.获取工作台首页的新闻列表（获取最新的10笔）
export function getTopNewsList(params) {
  return request({
    url: '/api/workbench/getTopNewsList',
    method: 'get',
    params: params
  })
}

// 获取所有历史新闻列表
export function getAllNewsList(params) {
  const { newstitle, pageindex, pagesize, userid } = params
  return request({
    url: '/api/workbench/getAllNewsList',
    method: 'get',
    params: { newstitle, pageindex, pagesize, userid }
  })
}

// 2.获取单个新闻的详细信息
export function getNewsInfo(params) {
  const { autoid, username } = params
  return request({
    url: '/api/workbench/getNewsInfo',
    method: 'get',
    params: { autoid, username }
  })
}

// 获取“我的指标”数（获取今年的360和KPI考核记录即可）
export function getMyKhCount(params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getMyKhCount',
    method: 'get',
    params: { empid }
  })
}

// 1.获取员工个人信息字段列表和字段值接口
export function getEmpPersonInfo(params) {
  const { empid } = params
  return request({
    url: '/api/wx/getEmpPersonInfo',
    method: 'get',
    params: { empid }
  })
}

// 2.保存员工个人信息接口
export function saveEmpPersonInfo(data) {
  return request({
    url: '/api/wx/saveEmpPersonInfo',
    method: 'post',
    data: data
  })
}

// 根据用户名获取短信验证码
export function getUserSmsCode(params) {
  const { username } = params
  return request({
    url: '/api/wx/getUserSmsCode',
    method: 'get',
    params: { username }
  })
}
// 获取首页未读消息数量
export function getAlarmMsgCount(params) {
  const { empid } = params
  return request({
    url: '/api/wx/getAlarmMsgCount',
    method: 'get',
    params: { empid }
  })
}
// 获取消息列表
export function getAlarmMsgList(params) {
  const { empid, isread } = params
  return request({
    url: '/api/wx/getAlarmMsgList',
    method: 'get',
    params: { empid, isread }
  })
}
// 获取消息详情
export function getAlarmMsgDetail(params) {
  const { autoid } = params
  return request({
    url: '/api/wx/getAlarmMsgDetail',
    method: 'get',
    params: { autoid }
  })
}
// 工资密码验证接口
export function checkSalaryPwd(data) {
  return request({
    url: '/api/wx/checkSalaryPwd',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}
// 获取指定范围内的工资签收记录列表接口（按月份或为空返回所有）
export function getEmpMonthSalaryList(params) {
  return request({
    url: '/api/wx/getEmpMonthSalaryList',
    method: 'get',
    params
  })
}
// 获取某个月份的工资主记录（可能一个月有多笔记录）
export function getEmpMonthSalaryMaster(params) {
  return request({
    url: '/api/wx/getEmpMonthSalaryMaster',
    method: 'get',
    params
  })
}
// 全部签收某个月的所有工资记录接口（可能一个月有多笔记录）
export function signMonthSalaryAll(data) {
  return request({
    url: '/api/wx/signMonthSalaryAll',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}
// 获取某个月单笔工资详细信息
export function getEmpMonthSalaryDetail(params) {
  return request({
    url: '/api/wx/getEmpMonthSalaryDetail',
    method: 'get',
    params
  })
}
// 签收某个月单笔工资接口
export function signMonthSalarySgl(data) {
  return request({
    url: '/api/wx/signMonthSalarySgl',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}
// 6.返回【我的】页签按钮列表接口
export function getWxMyBtnList(params) {
  return request({
    url: '/api/wx/getWxMyBtnList',
    method: 'get',
    params
  })
}
// 7.修改工资查询密码
export function changeSalaryPwd(params) {
  return request({
    url: '/api/login/changeSalaryPwd',
    method: 'get',
    params
  })
}
// 8.重置工资查询密码
export function setSalaryPwd(params) {
  return request({
    url: '/api/login/setSalaryPwd',
    method: 'get',
    params
  })
}

// 根据工号获取用户的其它信息
export function getEmpInfo(params) {
  return request({
    url: '/api/wx/getEmpInfo',
    method: 'get',
    params
  })
}

// 获取树层级
export function getSelTreeLevel(params) {
  return request({
    url: '/api/wx/getSelTreeLevel',
    method: 'get',
    params
  })
}

// 获取“我的证件”列表
export function getMyCertList(params) {
  return request({
    url: '/api/wx/getMyCertList',
    method: 'get',
    params
  })
}

// 获取“我的证件”详细信息
export function getMyCertInfo(params) {
  return request({
    url: '/api/wx/getMyCertInfo',
    method: 'get',
    params
  })
}

// 获取“我的证件”附件列表
export function getModuleAttFileList(params) {
  return request({
    url: '/api/wx/getModuleAttFileList',
    method: 'get',
    params
  })
}

// 保存一个证件详细信息接口
export function saveMyCertInfo(data) {
  return request({
    url: '/api/wx/saveMyCertInfo',
    method: 'post',
    data
  })
}

// 新增一个证件详细信息接口
export function addMyCertInfo(data) {
  return request({
    url: '/api/wx/addMyCertInfo',
    method: 'post',
    data
  })
}

// base64图片上传
export function upLoadBase64File(data) {
  return request({
    url: '/api/wx/upLoadBase64File',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

// 删除已上传图片
export function deleteFile(data) {
  return request({
    url: '/api/wx/deleteFile',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

// 绑定云之家
export function BindingYzj(params) {
  return request({
    url: '/api/wx/BindingYzj',
    method: 'get',
    params
  })
}
