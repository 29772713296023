<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  created() {
    // 用于钉钉jssdk鉴权，必修记录首次加载的地址
    window.globalurls = window.location.href
  }
}
</script>
<style lang="less">
#app {
  height: 100%;
  overflow: auto;
}
#v8_qrcode {
  width: 4.52rem !important;
  height: 4.52rem !important;
  margin: 0 auto !important;
  img {
    width: 4.52rem !important;
    height: 4.52rem !important;
  }
  canvas {
    width: 4.52rem !important;
    height: 4.52rem !important;
  }
}
.van-toast {
  width: 50% !important;
}
.MYNewsDetails {
  img {
    height: auto !important;
  }
  p {
    line-height: 1.5 !important;
    margin: 0 !important;
  }
}
</style>
