// 登陆
import request from '@/utils/request2'
export function checkUserLogin(data) {
  return request({
    url: '/api/wx/checkUserLogin',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
      lan_ip: window.lan_ip || '0.0.0.0',
      net_ip: window.net_ip || '0.0.0.0'
    },
    data
  })
}

// 登录前提示密码是否需要修改提醒接口
export function isChangePwd(params) {
  const { username } = params
  return request(
    {
      url: '/api/login/isChangePwd',
      method: 'get',
      params: { username }
    },
    '',
    false
  )
}
// 获取手机短信验证码接口
export function getSmsCode(params) {
  const { mobilephone } = params
  return request(
    {
      url: '/api/login/getSmsCode',
      method: 'get',
      params: { mobilephone }
    },
    '',
    false
  )
}
// 根据手机号找回密码接口
export function changePwdByMobilePhone(params) {
  const { username, mobilephone, newpwd1, newpwd2 } = params
  return request(
    {
      url: '/api/login/changePwdByMobilePhone',
      method: 'get',
      params: { username, mobilephone, newpwd1, newpwd2 }
    },
    '',
    false
  )
}
// 根据手机号找回密码接口（无用户名）
export function changePwdByPhone(params) {
  const { mobilephone, newpwd1, newpwd2 } = params
  return request(
    {
      url: '/api/login/changePwdByPhone',
      method: 'get',
      params: { mobilephone, newpwd1, newpwd2 }
    },
    '',
    false
  )
}

// 根据手机号码获取员工相关信息
export function getPhoneEmpInfo(params) {
  const { mobilephone } = params
  return request(
    {
      url: '/api/login/getPhoneEmpInfo',
      method: 'get',
      params: { mobilephone }
    },
    '',
    false
  )
}
